import { useEffect, useState, useCallback } from "react";

/**
 * Hook to watch passed media queries and return values based on window media query result.
 * @param queries - an array of media queries to watch
 * @param values - the values matching the media queries,
 * i.e. when a media query at an index is true, the value at the same index will be returned
 * @param defaultValue - default value to return if no media query is matched.
 */
function useMedia<T>(queries: string[], values: T[], defaultValue: T): T {
  // TODO: We should move this in a common repo.

  // Array containing a media query list for each query
  const mediaQueryLists = queries.map((q) => window.matchMedia(q));

  // Function that gets value based on matching media query
  const getValue = useCallback((): T => {
    // Get index of first media query that matches
    const index = mediaQueryLists.findIndex((mql) => mql.matches);
    // Return related value or defaultValue if none
    return typeof values[index] !== "undefined" ? values[index] : defaultValue;
  }, [mediaQueryLists, values, defaultValue]);

  // State and setter for matched value
  const [value, setValue] = useState(getValue);

  useEffect(() => {
    // Event listener callback
    // Note: By defining getValue outside of useEffect we ensure that it has ...
    // ... current values of hook args (as this hook callback is created once on mount).
    const handler = (): void => setValue(getValue);
    // Set a listener for each media query with above handler as callback.
    mediaQueryLists.forEach((mql) => mql.addListener(handler));
    // Remove listeners on cleanup
    return (): void =>
      mediaQueryLists.forEach((mql) => mql.removeListener(handler));
  }, [mediaQueryLists, getValue]);

  return value;
}

export default useMedia;
