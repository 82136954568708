import usersService from "./users";
import organizationsService from "./organizations";
import common from "./common";
import { api } from "@ebs-platform/common";

export default {
  ...api,
  users: usersService(api.config),
  organizations: organizationsService(api.config),
  common: common(api.config)
};
