import { ApiConfig } from "@ebs-platform/common";

export interface Country {
  key: number;
  label: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default (config: ApiConfig) => ({
  countryList: async (): Promise<Country[]> => {
    if (!config.tokens) {
      throw new Error("Session tokens not set");
    }

    const res = await fetch(`${config.baseUrl}/countries/`, {
      headers: { Authorization: `Token ${config.tokens.access}` }
    });
    const data = await res.json();

    if (res.ok) {
      return data;
    } else {
      throw new Error("Unexpected response.");
    }
  }
});
