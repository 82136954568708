// tslint:disable max-line-length
import React from "react";

const Edit: React.FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px">
    <path d="M9.362,2.992 L12.210,5.854 L5.001,13.098 L2.154,10.236 L9.362,2.992 ZM14.371,2.302 L13.101,1.026 C12.610,0.532 11.813,0.532 11.320,1.026 L10.104,2.248 L12.952,5.110 L14.371,3.684 C14.751,3.301 14.751,2.684 14.371,2.302 ZM0.664,14.259 C0.612,14.493 0.823,14.703 1.056,14.646 L4.230,13.873 L1.383,11.011 L0.664,14.259 Z" />
  </svg>
);

export default Edit;
