import React, { useEffect, useContext, useState, Suspense } from "react";
import { Spin } from "antd";
import { api, sso, AppSessionContext } from "@ebs-platform/common";
import PrivateRoutes from "libs/router/PrivateRoutes";

const localStorageField = "selectedAccount";

/**
 * This is `Fallback` component which is displyed while
 * `PrivateRoutes` are lazy loaded or validated for access.
 */
const Fallback: React.FC = () => (
  <div className="app-fallback">
    <Spin tip="Loading..." />
  </div>
);

/**
 * This component protects private routes from unauthticated users.
 *
 * With an server request for cookie valitation, SessionGuard will
 * verify user's session and store in SessionContext's AccountAccess.
 *
 * If AccountAccess proves to be a valid one, private routers are rendered
 * in the app's view, otherwise, it will redirect user to login page.
 */
const SessionGuard: React.FC = () => {
  const [isValidated, setValidation] = useState(false);
  const { setAccountAccess } = useContext(AppSessionContext);

  useEffect(() => {
    const checkCookie = async (): Promise<void> => {
      /* eslint-disable @typescript-eslint/no-non-null-assertion */
      sso.init({ baseUrl: process.env.REACT_APP_BASE_SSO! });
      api.init({ baseUrl: process.env.REACT_APP_BASE_API! });
      /* eslint-enable @typescript-eslint/no-non-null-assertion */

      const sessionTokens = await sso.authorization.checkCookie();

      if (sessionTokens) {
        api.setSessionTokens(sessionTokens);
        const storedValue = localStorage.getItem(localStorageField);

        if (storedValue) {
          try {
            const user = JSON.parse(storedValue);

            setAccountAccess(user);
          } catch {
            localStorage.removeItem(localStorageField);
          }
        }
      }
      setValidation(true);
    };

    checkCookie();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isValidated) {
    return <Fallback />;
  }

  return (
    <Suspense fallback={<Fallback />}>
      <PrivateRoutes />
    </Suspense>
  );
};

export default SessionGuard;
