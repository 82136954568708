import React, { MouseEventHandler } from "react";
import { classnames } from "libs/utils";
import "./Button.css";

export interface ButtonTypes extends React.HTMLProps<HTMLButtonElement> {
  icon?: React.ReactNode;
  children: React.ReactChild;
  className?: string;
  onClick?: MouseEventHandler;
  primary?: boolean;
  splited?: boolean;
  cancel?: boolean;
  save?: boolean;
  submit?: boolean;
  type?: "button" | "submit" | "reset";
}

/**
 * `Button` Component
 *
 * This component is used to create a button.
 *
 * It has multiple possible props that allows
 * to customise properly and reuse it anywhere.
 *
 * It can be used along an icon by send one in
 * `prefix` prop.
 *
 * `children` are the only required props.
 *
 * @example
 *
 * <Button
 *  children={OrganizationType}
 *  onClick={MouseEventHandler}
 *  primary
 * />
 *
 * @deprecated [Please use antd Button]
 */
const Button: React.FC<ButtonTypes> = ({
  children,
  className,
  onClick,
  icon,
  primary,
  splited,
  cancel,
  save,
  submit,
  ...props
}) => {
  if (process.env.NODE_ENV !== "production") {
    // tslint:disable-next-line: no-console
    console.warn("This component is deprecated, please use antd Button");
  }

  return (
    <button
      className={classnames("button", className, {
        "button--primary": primary,
        "button--splited": splited,
        "button--cancel": cancel,
        "button--save": save,
        "button--submit": submit
      })}
      onClick={onClick}
      {...props}
    >
      {icon ? icon : null}
      <span>{children}</span>
    </button>
  );
};

export default Button;
