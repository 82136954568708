import React, { useState } from "react";
import { Dropdown, Menu } from "antd";
import { Button } from "components/ui";
import { Sort } from "components/svg";
import "./SortButton.css";

export interface SortButtonProps {
  onSort: (sort: string) => void;
  value: string;
  variants: Array<{ value: string; label: string }>;
}

const { Item } = Menu;

/**
 * `SortButton` Component
 *
 * This component is used to display a dropdown list based on `sortValues`
 * and calls `onSort` when certain item was selected.
 *
 * `sortBy` represents current list selection
 *
 * @example
 *
 * <SortButton
 *  onSort={onOrganizationSort}
 *  sortBy={"name"}
 *  sortValues={["name", "popularity"]}
 * />
 *
 */

const SortButton: React.FC<SortButtonProps> = ({ onSort, value, variants }) => {
  const [active, setActive] = useState<boolean>(false);

  const changeActive = (isActive: boolean): void => setActive(isActive);

  const menu = (
    <Menu selectedKeys={[value]}>
      <span className="sort-dropdown__title">SORT BY</span>
      {variants.map((v) => (
        <Item onClick={(): void => onSort(v.value)} key={v.value}>
          {`By ${v.label}`}
        </Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown
      overlay={menu}
      placement="bottomRight"
      trigger={["click"]}
      onVisibleChange={changeActive}
      overlayClassName="sort-dropdown"
    >
      {/* eslint-disable-next-line */}
      <a href="#">
        <Button primary splited icon={<Sort active={active} />}>
          {`By ${value}`}
        </Button>
      </a>
    </Dropdown>
  );
};

export default SortButton;
