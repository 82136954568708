/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Anchor } from "antd";
import { Link as OldLink } from "react-router-dom";
import { getRoute, getQueryString } from "libs/router";
import { LocationDescriptor } from "history";

export interface LinkProps {
  anchor?: string;
  route?: string;
  query?: string;
  params?: any;
  to?: LocationDescriptor<any>;
  children: React.ReactChild;
  className?: string;
}

const Link: React.FC<LinkProps> = ({
  anchor,
  to,
  route,
  query = "",
  params = {},
  children,
  className,
  ...props
}) => {
  if (anchor) {
    return <Anchor.Link href={anchor} title={children} />;
  }

  // prettier-ignore
  const toLinkParam =
    query ? `?${getQueryString(query)}` :
      route ? getRoute(route, params) : to;

  if (toLinkParam) {
    return (
      <OldLink className={className} to={toLinkParam} {...props}>
        {children}
      </OldLink>
    );
  }

  return null;
};

export default Link;
