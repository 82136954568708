// tslint:disable max-line-length
import React from "react";

const User: React.FC = () => (
  <svg width="18px" height="18px">
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M14.422,16.179 C14.383,16.214 14.340,16.246 14.293,16.274 C12.807,17.359 10.977,18.000 9.000,18.000 C7.023,18.000 5.193,17.359 3.707,16.274 C3.660,16.246 3.617,16.214 3.578,16.179 C1.406,14.534 -0.000,11.928 -0.000,9.000 C-0.000,4.037 4.037,-0.000 9.000,-0.000 C13.962,-0.000 18.000,4.037 18.000,9.000 C18.000,11.928 16.594,14.534 14.422,16.179 ZM9.000,16.363 C10.376,16.363 11.665,15.984 12.768,15.324 C11.904,14.117 10.517,13.391 9.000,13.391 C7.483,13.391 6.095,14.117 5.232,15.324 C6.335,15.984 7.624,16.363 9.000,16.363 ZM9.000,1.636 C4.940,1.636 1.636,4.940 1.636,9.000 C1.636,11.097 2.517,12.992 3.929,14.334 C5.098,12.723 6.963,11.755 9.000,11.755 C11.037,11.755 12.902,12.723 14.071,14.334 C15.482,12.992 16.364,11.097 16.364,9.000 C16.364,4.940 13.060,1.636 9.000,1.636 ZM9.000,10.119 C7.113,10.119 5.577,8.583 5.577,6.696 C5.577,4.808 7.113,3.273 9.000,3.273 C10.887,3.273 12.423,4.808 12.423,6.696 C12.423,8.583 10.887,10.119 9.000,10.119 ZM9.000,4.909 C8.015,4.909 7.213,5.711 7.213,6.696 C7.213,7.681 8.015,8.482 9.000,8.482 C9.985,8.482 10.786,7.681 10.786,6.696 C10.786,5.711 9.985,4.909 9.000,4.909 Z"
    />
  </svg>
);

export default User;
