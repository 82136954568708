import React, { useCallback } from "react";
import { Layout as AntdLayout, message } from "antd";
import { useHistory } from "react-router-dom";
import RocketAppsLogo from "assets/images/RocketAppsLogo.png";
import { Header, Sidebar, Layout as EbsLayout } from "@ebs-platform/components";
import "./Layout.css";
import { MenuItem } from "@ebs-platform/components/lib/components/Sidebar/interfaces";
import queryString from "query-string";
import { BillingIcon, Organization } from "components/svg";
import AccountDropdown from "components/AccountDropdown";
import { sso } from "@ebs-platform/common";
import { useAccount } from "hooks";

export interface LayoutInterface {
  children: React.ReactNode;
  className?: string;
}

const { Content } = AntdLayout;

/**
 * This renders the app layout.
 *
 * Currently used only in {@link PrivateRoute} component for
 * each private route, that means that any local state to this component
 * will be destroyed on page navigation.
 */
const Layout: React.FC<LayoutInterface> = ({ children, className }) => {
  const history = useHistory();
  const { user } = useAccount();

  const handleLogout = useCallback(async (): Promise<void> => {
    try {
      await sso.authorization.logout();
      message.success("You have been successfully logged out");
      window.location.href = "/authentication/login";
    } catch (e) {
      message.error((e as Error).toString());
    }
  }, []);

  const menu: MenuItem[] = React.useMemo(
    () => [
      {
        key: "organizations",
        icon: <Organization />,
        title: "Organizations",
        onClick: (): void => history.push("/")
      },
      {
        key: "billings",
        icon: <BillingIcon />,
        title: "Billing",
        onClick: (): void => history.push("/billing")
      }
    ],
    [history]
  );

  const paths = queryString.parse(
    history.location.pathname.replace(/\//, "?").replace(/\//, "&")
  );

  const selectedKeys = React.useMemo(
    () => [
      ...Object.keys(paths)
        .filter((i) => i)
        .map((i) => {
          if (`s` !== i[i.length - 1]) return `${i}s`;
          else if ("ss" === i.slice(i.length - 2)) return `${i}es`;
          else return i;
        })
    ],
    [paths]
  );

  return (
    <EbsLayout>
      <div className="flex">
        <div style={{ flex: 1 }}>
          <Header logo={{ title: "Account", src: RocketAppsLogo }} />
        </div>
        <AntdLayout.Header className="ebs-header">
          <AccountDropdown
            name={`${user?.first_name} ${user?.last_name}`}
            imageUrl={user?.photo}
            onLogout={handleLogout}
          />
        </AntdLayout.Header>
      </div>
      <EbsLayout>
        <Sidebar
          items={menu}
          selectedKey={selectedKeys.shift() || "organizations"}
        ></Sidebar>
        <Content className={`layout app-content ${className || ""}`}>
          {children}
        </Content>
      </EbsLayout>
    </EbsLayout>
  );
};

export default Layout;
