// tslint:disable max-line-length
import React from "react";

const User: React.FC = () => (
  <svg width="14px" height="19px">
    <path
      fillRule="evenodd"
      fill="currentColor"
      d="M13.192,17.207 L10.769,17.207 L10.769,18.026 C10.769,18.267 10.664,18.497 10.481,18.652 C10.334,18.777 10.150,18.844 9.961,18.844 C9.915,18.844 9.868,18.840 9.821,18.831 L0.667,17.195 C0.281,17.126 -0.000,16.786 -0.000,16.389 L-0.000,3.299 C-0.000,2.902 0.281,2.562 0.667,2.493 L9.821,0.856 C10.056,0.814 10.298,0.880 10.480,1.035 C10.663,1.190 10.769,1.420 10.769,1.662 L10.769,2.481 L13.192,2.480 C13.192,2.480 13.192,2.480 13.192,2.480 C13.406,2.480 13.612,2.566 13.763,2.720 C13.915,2.873 14.000,3.081 14.000,3.298 L14.000,16.389 C14.000,16.841 13.638,17.207 13.192,17.207 ZM9.154,16.389 L9.154,3.299 L9.154,2.637 L1.615,3.986 L1.615,15.703 L9.154,17.050 L9.154,16.389 ZM12.385,4.117 L10.769,4.117 L10.769,15.571 L12.385,15.571 L12.385,4.117 ZM6.731,9.571 C6.943,9.571 7.152,9.658 7.302,9.810 C7.452,9.963 7.538,10.174 7.538,10.389 C7.538,10.605 7.452,10.815 7.302,10.968 C7.152,11.120 6.943,11.207 6.731,11.207 C6.518,11.207 6.310,11.120 6.159,10.968 C6.009,10.815 5.923,10.605 5.923,10.389 C5.923,10.174 6.009,9.963 6.159,9.810 C6.310,9.658 6.518,9.571 6.731,9.571 Z"
    />
  </svg>
);

export default User;
