// tslint:disable max-line-length
import React from "react";

const Organizations: React.FC = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 13.25C10.5858 13.25 10.25 13.5858 10.25 14C10.25 14.4142 10.5858 14.75 11 14.75V13.25ZM17 14.75C17.4142 14.75 17.75 14.4142 17.75 14C17.75 13.5858 17.4142 13.25 17 13.25V14.75ZM14.75 11C14.75 10.5858 14.4142 10.25 14 10.25C13.5858 10.25 13.25 10.5858 13.25 11H14.75ZM13.25 17C13.25 17.4142 13.5858 17.75 14 17.75C14.4142 17.75 14.75 17.4142 14.75 17H13.25ZM2 1.75H6V0.25H2V1.75ZM6 1.75C6.13807 1.75 6.25 1.86193 6.25 2H7.75C7.75 1.0335 6.9665 0.25 6 0.25V1.75ZM6.25 2V6H7.75V2H6.25ZM6.25 6C6.25 6.13807 6.13807 6.25 6 6.25V7.75C6.9665 7.75 7.75 6.9665 7.75 6H6.25ZM6 6.25H2V7.75H6V6.25ZM2 6.25C1.86193 6.25 1.75 6.13807 1.75 6H0.25C0.25 6.9665 1.0335 7.75 2 7.75V6.25ZM1.75 6V2H0.25V6H1.75ZM1.75 2C1.75 1.86193 1.86193 1.75 2 1.75V0.25C1.0335 0.25 0.25 1.0335 0.25 2H1.75ZM12 1.75H16V0.25H12V1.75ZM16 1.75C16.1381 1.75 16.25 1.86193 16.25 2H17.75C17.75 1.0335 16.9665 0.25 16 0.25V1.75ZM16.25 2V6H17.75V2H16.25ZM16.25 6C16.25 6.13807 16.1381 6.25 16 6.25V7.75C16.9665 7.75 17.75 6.9665 17.75 6H16.25ZM16 6.25H12V7.75H16V6.25ZM12 6.25C11.8619 6.25 11.75 6.13807 11.75 6H10.25C10.25 6.9665 11.0335 7.75 12 7.75V6.25ZM11.75 6V2H10.25V6H11.75ZM11.75 2C11.75 1.86193 11.8619 1.75 12 1.75V0.25C11.0335 0.25 10.25 1.0335 10.25 2H11.75ZM2 11.75H6V10.25H2V11.75ZM6 11.75C6.13807 11.75 6.25 11.8619 6.25 12H7.75C7.75 11.0335 6.9665 10.25 6 10.25V11.75ZM6.25 12V16H7.75V12H6.25ZM6.25 16C6.25 16.1381 6.13807 16.25 6 16.25V17.75C6.9665 17.75 7.75 16.9665 7.75 16H6.25ZM6 16.25H2V17.75H6V16.25ZM2 16.25C1.86193 16.25 1.75 16.1381 1.75 16H0.25C0.25 16.9665 1.0335 17.75 2 17.75V16.25ZM1.75 16V12H0.25V16H1.75ZM1.75 12C1.75 11.8619 1.86193 11.75 2 11.75V10.25C1.0335 10.25 0.25 11.0335 0.25 12H1.75ZM11 14.75H17V13.25H11V14.75ZM13.25 11V17H14.75V11H13.25Z"
      fill="currentColor"
    />
  </svg>
);

export default Organizations;
