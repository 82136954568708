// tslint:disable max-line-length
import React from "react";

const Close: React.FC = () => (
  <svg width="26px" height="26px">
    <rect width="100%" height="100%" rx="50%" ry="50%" fill="rgb(9, 68, 157)" />
    <path
      fill="rgb(180, 212, 253)"
      transform="translate(7,7)"
      d="M0.909,9.394 L4.303,6.000 L0.909,2.606 L2.606,0.909 L6.000,4.303 L9.394,0.909 L11.091,2.606 L7.697,6.000 L11.091,9.394 L9.394,11.091 L6.000,7.697 L2.606,11.091 L0.909,9.394 Z"
    />
  </svg>
);

export default Close;
