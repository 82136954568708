// tslint:disable max-line-length
import React from "react";

export interface SortTypes {
  active?: boolean;
}

const Sort: React.FC<SortTypes> = ({ active }) => (
  <svg width="16px" height="16px">
    <path
      d={
        active
          ? "M6.666,3.353 L6.666,2.020 L16.000,2.020 L16.000,3.353 L6.666,3.353 ZM3.333,2.963 L3.333,12.688 L2.000,12.688 L2.000,2.963 L0.943,4.020 L-0.000,3.077 L2.195,0.882 C2.455,0.621 2.877,0.621 3.138,0.882 L5.333,3.077 L4.390,4.019 L3.333,2.963 ZM14.000,6.020 L6.666,6.020 L6.666,4.687 L14.000,4.687 L14.000,6.020 ZM12.000,8.687 L6.666,8.687 L6.666,7.354 L12.000,7.354 L12.000,8.687 ZM10.000,11.354 L6.666,11.354 L6.666,10.021 L10.000,10.021 L10.000,11.354 Z"
          : "M6.666,2.661 L6.666,1.331 L16.000,1.331 L16.000,2.661 L6.666,2.661 ZM2.195,11.782 L-0.000,9.591 L0.943,8.650 L2.000,9.705 L2.000,-0.000 L3.333,-0.000 L3.333,9.705 L4.390,8.649 L5.333,9.591 L3.138,11.782 C2.878,12.041 2.456,12.041 2.195,11.782 ZM14.000,5.323 L6.666,5.323 L6.666,3.992 L14.000,3.992 L14.000,5.323 ZM12.000,7.984 L6.666,7.984 L6.666,6.653 L12.000,6.653 L12.000,7.984 ZM10.000,10.645 L6.666,10.645 L6.666,9.315 L10.000,9.315 L10.000,10.645 Z"
      }
    />
  </svg>
);

export default Sort;
