import React from "react";
import { Image, Arrow } from "components/svg";
import "./AccountCard.css";
import { AccountAccess } from "libs/api/users";

export interface AccountCardProps {
  accountAccess: AccountAccess;
  onClick: (account: AccountAccess) => void;
  image?: string;
}

const AccountCard: React.FC<AccountCardProps> = ({
  accountAccess,
  onClick,
  image
}) => (
  <div
    className="organization-card"
    onClick={(): void => onClick(accountAccess)}
  >
    {image ? (
      <img
        className="organization__image"
        src={image}
        alt="Organization Logo"
      />
    ) : (
      <Image.Placeholder />
    )}
    <div className="details">
      <div className="details__name">{accountAccess.account.name}</div>
      <div className="details__role">{accountAccess.role}</div>
    </div>
    <span className="arrow">
      <Arrow.Forward />
    </span>
  </div>
);

export default AccountCard;
