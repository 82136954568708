import routes from "libs/router/routes";
import { LocationDescriptor } from "history";

/**
 * This function return route path used by Link component
 * for navigating throught application.
 *
 * @param name
 * @param params
 *
 * @example
 * getRoute('table', { page: 1 })
 */
export const getRoute = (
  name: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any = {}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): LocationDescriptor<any> | undefined => {
  const appRoute = routes.find((item) => item.name === name);

  if (appRoute) {
    let { path } = Object.assign({}, appRoute);

    // TODO: there is a bug here for optional params
    Object.keys(params).map(
      (key) => (path = path.replace(`:${key}`, params[key]))
    );

    return path;
  }

  return undefined;
};

/**
 *
 * This function returns route by providing a path.
 *
 * @param path
 */
export const getRouteByPath = (path: string): string | undefined => {
  const route = routes.find((item) => item.path === path);

  if (route) {
    return route.name;
  }
};

export const getQueryString = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  params: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): LocationDescriptor<any> | undefined => {
  return new URLSearchParams(params).toString();
};
