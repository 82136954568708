import React, { useMemo, useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AppSessionContext } from "@ebs-platform/common";
import { Layout } from "components";
import routes from "./routes";

/**
 * `PrivateRoutes` will render all private routes only if
 * there is a valid session detect and accountAccess setted.
 *
 * Otherwise, this component will redirect user to login path
 * to reauthenticate.
 */
const PrivateRoutes: React.FC = () => {
  const { session } = useContext(AppSessionContext);

  const privateRoutes = useMemo(() => {
    return routes.filter(({ isPublic }) => !isPublic);
  }, []);

  if (!session.isSessionValid || !session.accountAccess) {
    return <Redirect to="/authentication/login" />;
  }

  return (
    <Layout>
      {privateRoutes.map((r) => {
        const { name, ...rest } = r;

        return <Route key={name} {...rest} />;
      })}
    </Layout>
  );
};

export default PrivateRoutes;
