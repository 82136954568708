import React, { useMemo } from "react";
import { Avatar, Dropdown, Menu } from "antd";

import { Link } from "libs/router";
import { firstLetters } from "libs/utils";

import "./AccountDropdown.css";
import { Exit, User } from "components/svg";

interface AccountDropdownProps {
  name?: string;
  imageUrl?: string;
  onLogout?: () => void;
}

const AccountDropdown: React.FC<AccountDropdownProps> = ({
  name,
  imageUrl,
  onLogout
}) => {
  const menu = useMemo(
    () => (
      <Menu mode="inline">
        <Menu.Item key="0">
          <Link to="/profile">
            <span className="dropdown-account__item">
              <User /> My Profile
            </span>
          </Link>
        </Menu.Item>
        <Menu.Item key="1" onClick={onLogout}>
          <span className="dropdown-account__item">
            <Exit /> Logout
          </span>
        </Menu.Item>
      </Menu>
    ),
    []
  );

  return (
    <Dropdown className="dropdown-account" overlay={menu} trigger={["click"]}>
      <a
        className="ant-dropdown-link"
        onClick={(e): void => e.preventDefault()}
      >
        <span className="dropdown-account__name">{name}</span>
        <Avatar
          className="dropdown-account__avatar"
          shape="square"
          size="large"
          icon={!name && "user"}
          src={imageUrl}
        >
          {firstLetters(name)}
        </Avatar>
      </a>
    </Dropdown>
  );
};

export default AccountDropdown;
