import React, { useEffect, useState } from "react";
import LogoPlaceholder from "./images/LogoPlaceholder.png";
import { Upload } from "components/ui";
import { Image } from "components/svg";
import "./LogoUpload.css";

export interface LogoUploadProps {
  onChange?: (imageUrl?: string) => void;
  title?: string;
  imageUrl?: string;
  size?: "large" | "small";
  onRemove?: () => void;
}

const LogoUpload: React.FC<LogoUploadProps> = ({
  onChange,
  title = "Upload logo",
  imageUrl,
  size = "large",
  onRemove
}) => {
  const [image, setImage] = useState(imageUrl);

  useEffect(() => {
    setImage(imageUrl);
  }, [imageUrl]);

  useEffect(() => {
    onChange?.(image);
  }, [image]);

  const setImageUrl = (fileUrl: string): void => {
    setImage(fileUrl);
  };

  return (
    <div className={`logo-upload logo-upload--${size}`}>
      <div className="image">
        <img src={image || LogoPlaceholder} alt={title} />
        {onRemove && imageUrl && (
          <div className="image__actions">
            <span className="actions__icon" onClick={onRemove}>
              <Image.Remove />
            </span>
          </div>
        )}
      </div>

      <div>
        <div className="title">{title}</div>
        <div className="description">
          Maximum file size 20 mb, accepted formats: PNG, JPG
        </div>
        <Upload onChange={setImageUrl} image>
          {image ? "Change image" : "Upload image"}
        </Upload>
      </div>
    </div>
  );
};

export default LogoUpload;
