import React, { useEffect } from "react";
import { SortButton, Button } from "components/ui";

export interface ActionsProps {
  onOrganizationSort?: (sort: string) => void;
  onInvocesSort?: () => void;
  onSave?: () => void;
  onCancel?: () => void;
  sortBy?: string;
}

/**
 * `Actions` Component
 *
 * This component can create a series of buttons.
 * It is used in page header, and to return `Save/Cancel`
 * button collection.
 *
 * Depends on props recevied, it will diplay certain button
 * by function that need to be called.
 *
 *
 * @example
 *
 * <Button onSave={() => void} />
 *
 */
const Actions: React.FC<ActionsProps> = ({
  onOrganizationSort,
  onInvocesSort,
  onSave,
  onCancel,
  ...props
}) => {
  useEffect(() => {
    const handleUserKeyPress = (event: KeyboardEvent): void => {
      const { ctrlKey, key } = event;

      if (ctrlKey && key === "Enter" && onSave) {
        onSave();
      } else if (key === "Escape" && onCancel) {
        onCancel();
      }
    };

    if (onSave || onCancel) {
      window.addEventListener("keydown", handleUserKeyPress);
    }

    return (): void => {
      window.removeEventListener("keydown", handleUserKeyPress);
    };
  }, [onSave, onCancel]);

  return (
    <>
      {onOrganizationSort ? (
        <SortButton
          onSort={onOrganizationSort}
          value={props.sortBy || "name"}
          variants={[
            { value: "name", label: "name" },
            { value: "popularity", label: "popularity" }
          ]}
        />
      ) : null}
      {onInvocesSort ? (
        <Button primary onClick={onInvocesSort}>
          onInvocesSort
        </Button>
      ) : null}
      {onCancel ? (
        <Button onClick={onCancel} primary cancel>
          <>
            Cancel<sup>Esc</sup>
          </>
        </Button>
      ) : null}
      {onSave ? (
        <Button onClick={onSave} primary save>
          <>
            Save<sup>Ctrl+Enter</sup>
          </>
        </Button>
      ) : null}
    </>
  );
};

export default Actions;
