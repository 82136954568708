import React, { useMemo } from "react";
import { Route, Switch } from "react-router-dom";
import routes from "./routes";

/**
 * This component is used in App component
 * to render all public routes, without conditions.
 *
 * PublicRoutes uses filtered results of all
 * `routes` which has `isPublic` flag.
 */
const PublicRoutes: React.FC = () => {
  const publicRoutes = useMemo(
    () => routes.filter(({ isPublic }) => isPublic),
    []
  );

  return (
    <Switch>
      {publicRoutes.map(({ name, ...params }) => (
        <Route key={name} {...params} />
      ))}
    </Switch>
  );
};

export default PublicRoutes;
