import React from "react";
import "./Copyright.css";

const Copyright: React.FC = () => {
  return (
    <p className="copyright">
      Designed by{" "}
      <a className="copyright__link" href="https://ebs-integrator.com">
        EBSIntegrator
      </a>
    </p>
  );
};

export default Copyright;
