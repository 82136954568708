// tslint:disable max-line-length
import React from "react";

const Placeholder: React.FC = () => (
  <svg width="30px" height="26px">
    <path
      fill="rgb(137, 147, 164)"
      d="M26.481,26.000 L3.519,26.000 C1.578,26.000 -0.000,24.409 -0.000,22.455 L-0.000,3.545 C-0.000,1.590 1.578,-0.000 3.519,-0.000 L26.481,-0.000 C28.421,-0.000 30.000,1.590 30.000,3.545 L30.000,22.455 C30.000,24.409 28.421,26.000 26.481,26.000 ZM27.654,3.545 C27.654,2.894 27.128,2.364 26.481,2.364 L3.519,2.364 C2.872,2.364 2.346,2.894 2.346,3.545 L2.346,12.489 L7.198,5.343 L11.091,10.245 L12.855,7.690 L21.780,19.382 L27.654,19.382 L27.654,3.545 ZM27.654,21.745 L20.625,21.745 L12.950,11.691 L6.358,21.238 C5.988,21.774 5.257,21.907 4.726,21.534 C4.194,21.161 4.063,20.424 4.433,19.889 L9.693,12.269 L7.346,9.312 L2.346,16.675 L2.346,22.455 C2.346,23.107 2.871,23.636 3.519,23.636 L26.481,23.636 C27.129,23.636 27.654,23.107 27.654,22.455 L27.654,21.745 ZM18.622,7.800 C18.622,5.845 20.201,4.254 22.141,4.254 C24.081,4.254 25.660,5.845 25.660,7.800 C25.660,9.755 24.081,11.345 22.141,11.345 C20.201,11.345 18.622,9.755 18.622,7.800 ZM22.141,8.982 C22.788,8.982 23.314,8.452 23.314,7.800 C23.314,7.148 22.788,6.618 22.141,6.618 C21.494,6.618 20.968,7.148 20.968,7.800 C20.968,8.452 21.494,8.982 22.141,8.982 Z"
    />
  </svg>
);

const UploadPlaceholder: React.FC = () => (
  <svg width="70px" height="70px">
    <rect width="100%" height="100%" rx="5" ry="5" />
    <path
      transform="translate(20,20)"
      d="M26.481,26.000 L3.519,26.000 C1.578,26.000 -0.000,24.410 -0.000,22.455 L-0.000,3.545 C-0.000,1.590 1.578,-0.000 3.519,-0.000 L26.481,-0.000 C28.421,-0.000 30.000,1.590 30.000,3.545 L30.000,22.455 C30.000,24.410 28.421,26.000 26.481,26.000 ZM27.654,3.545 C27.654,2.894 27.128,2.364 26.481,2.364 L3.519,2.364 C2.872,2.364 2.346,2.894 2.346,3.545 L2.346,12.489 L7.198,5.343 L11.091,10.245 L12.855,7.690 L21.780,19.382 L27.654,19.382 L27.654,3.545 ZM27.654,21.745 L20.625,21.745 L12.950,11.691 L6.358,21.239 C5.988,21.774 5.257,21.907 4.726,21.534 C4.194,21.161 4.063,20.425 4.433,19.889 L9.693,12.269 L7.346,9.312 L2.346,16.675 L2.346,22.455 C2.346,23.107 2.871,23.636 3.519,23.636 L26.481,23.636 C27.129,23.636 27.654,23.107 27.654,22.455 L27.654,21.745 ZM18.622,7.800 C18.622,5.845 20.201,4.254 22.141,4.254 C24.082,4.254 25.660,5.845 25.660,7.800 C25.660,9.755 24.082,11.345 22.141,11.345 C20.201,11.345 18.622,9.755 18.622,7.800 ZM22.141,8.982 C22.788,8.982 23.314,8.452 23.314,7.800 C23.314,7.148 22.788,6.618 22.141,6.618 C21.495,6.618 20.968,7.148 20.968,7.800 C20.968,8.452 21.495,8.982 22.141,8.982 Z"
    />
  </svg>
);

const AddIcon: React.FC = () => (
  <svg width="21px" height="18px">
    <path d="M21.000,2.269 L21.000,11.231 C21.000,11.544 20.724,11.798 20.384,11.798 L19.974,11.798 C19.633,11.798 19.358,11.544 19.358,11.231 L19.358,2.269 C19.358,1.851 18.990,1.512 18.537,1.512 L2.463,1.512 C2.010,1.512 1.642,1.851 1.642,2.269 L1.642,7.992 L5.039,3.419 L7.763,6.556 L8.998,4.921 L14.267,11.231 L14.267,11.231 C14.267,11.544 13.991,11.798 13.651,11.798 L12.669,11.798 L9.065,7.482 L4.802,13.126 C4.608,13.383 4.224,13.447 3.945,13.268 L3.608,13.052 C3.329,12.873 3.260,12.519 3.454,12.262 L6.785,7.851 L5.142,5.959 L1.642,10.671 L1.642,14.370 C1.642,14.787 2.010,15.126 2.463,15.126 L10.244,15.126 C10.584,15.126 10.860,15.380 10.860,15.693 L10.860,16.071 C10.860,16.385 10.584,16.639 10.244,16.639 L2.463,16.639 C1.103,16.639 -0.000,15.623 -0.000,14.370 L-0.000,2.269 C-0.000,1.016 1.103,-0.000 2.463,-0.000 L18.537,-0.000 C19.897,-0.000 21.000,1.016 21.000,2.269 ZM15.499,7.260 C14.141,7.260 13.036,6.243 13.036,4.991 C13.036,3.740 14.141,2.722 15.499,2.722 C16.857,2.722 17.962,3.740 17.962,4.991 C17.962,6.243 16.857,7.260 15.499,7.260 ZM16.320,4.991 C16.320,4.574 15.952,4.235 15.499,4.235 C15.046,4.235 14.678,4.574 14.678,4.991 C14.678,5.409 15.046,5.748 15.499,5.748 C15.952,5.748 16.320,5.409 16.320,4.991 ZM16.936,10.134 L16.525,10.134 C16.185,10.134 15.909,10.388 15.909,10.702 L15.909,13.311 L13.077,13.311 C12.737,13.311 12.461,13.565 12.461,13.878 L12.461,14.256 C12.461,14.569 12.737,14.823 13.077,14.823 L15.909,14.823 L15.909,17.433 C15.909,17.746 16.185,18.000 16.525,18.000 L16.936,18.000 C17.276,18.000 17.552,17.746 17.552,17.433 L17.552,14.823 L20.384,14.823 C20.724,14.823 21.000,14.569 21.000,14.256 L21.000,13.878 C21.000,13.565 20.724,13.311 20.384,13.311 L17.552,13.311 L17.552,10.702 C17.552,10.388 17.276,10.134 16.936,10.134 Z" />
  </svg>
);

const Remove: React.FC = () => (
  <svg width="30px" height="30px">
    <rect width="100%" height="100%" fill="#b9c8d6" />
    <path
      transform="translate(9,8)"
      fill="#ffffff"
      d="M1.852,12.513 C1.933,13.361 2.646,14.000 3.511,14.000 L8.488,14.000 C9.353,14.000 10.067,13.361 10.148,12.513 L10.943,4.184 L1.056,4.184 L1.852,12.513 ZM6.852,8.148 L6.790,9.926 L5.210,9.926 L5.148,8.148 L6.852,8.148 ZM5.109,7.055 L5.047,5.277 L6.953,5.277 L6.890,7.055 L5.109,7.055 ZM7.964,8.148 L9.449,8.148 L9.279,9.926 L7.902,9.926 L7.964,8.148 ZM6.751,11.019 L6.685,12.906 L5.314,12.906 L5.248,11.019 L6.751,11.019 ZM4.098,9.926 L2.721,9.926 L2.551,8.148 L4.036,8.148 L4.098,9.926 ZM2.958,12.410 L2.825,11.019 L4.137,11.019 L4.203,12.906 L3.511,12.906 C3.223,12.906 2.985,12.693 2.958,12.410 ZM8.488,12.906 L7.797,12.906 L7.863,11.019 L9.174,11.019 L9.041,12.410 C9.014,12.693 8.777,12.906 8.488,12.906 ZM9.553,7.055 L8.002,7.055 L8.064,5.277 L9.723,5.277 L9.553,7.055 ZM3.935,5.277 L3.998,7.055 L2.447,7.055 L2.277,5.277 L3.935,5.277 ZM8.639,1.994 L8.639,1.640 C8.639,0.736 7.891,-0.000 6.972,-0.000 L5.028,-0.000 C4.109,-0.000 3.361,0.736 3.361,1.640 L3.361,1.994 L-0.000,1.994 L-0.000,3.088 L12.000,3.088 L12.000,1.994 L8.639,1.994 ZM4.472,1.640 C4.472,1.339 4.721,1.094 5.028,1.094 L6.972,1.094 C7.278,1.094 7.528,1.339 7.528,1.640 L7.528,1.994 L4.472,1.994 L4.472,1.640 Z"
    />
  </svg>
);

export default {
  Placeholder,
  UploadPlaceholder,
  AddIcon,
  Remove
};
