import React from "react";
import { Upload, message, Button as AntdButton } from "antd";
import { Image } from "components/svg";
import fetch from "cross-fetch";
import { RcFile } from "antd/lib/upload";

const baseUrl = process.env.REACT_APP_BASE_FS;

export interface UploadFormProps {
  children: React.ReactChild;
  image?: boolean;
  name?: string;
  onChange?: (value: string) => void;
}

export interface UploadFormState {
  loading: boolean;
}

class UploadFormItem extends React.PureComponent<
  UploadFormProps,
  UploadFormState
> {
  constructor(props: UploadFormProps) {
    super(props);

    this.state = { loading: false };

    this.uploadFile = this.uploadFile.bind(this);
  }

  public beforeUpload(file: RcFile): boolean {
    const isLt10M = file.size / 1024 / 1024 < 20;
    if (!isLt10M) {
      message.error("Image must smaller than 20MB!");
    }
    return isLt10M;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public uploadFile(info: any): Promise<void> {
    if (!baseUrl) {
      throw new Error("FS base url not set");
    }

    const formData = new FormData();
    formData.append("file", info.file);
    this.setState({ loading: true });

    return fetch(`${baseUrl}/file/upload`, {
      method: "POST",
      body: formData
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
      })
      .then((data: File) => {
        if (this.props.onChange) {
          this.props.onChange(data.url);
        }
        this.setState({ loading: false });
      })
      .catch(() => {
        this.setState({ loading: false });
        message.error("Uploading image error");
      });
  }

  public render(): React.ReactNode {
    const { loading } = this.state;
    const { children, image } = this.props;

    if (image) {
      return (
        <div>
          <Upload
            accept=".jpg,.png"
            listType="text"
            showUploadList={false}
            disabled={loading}
            customRequest={this.uploadFile}
            beforeUpload={this.beforeUpload}
          >
            <AntdButton
              htmlType="button"
              loading={loading}
              style={{
                display: "inline-flex",
                alignItems: "center",
                gap: "10px"
              }}
            >
              <Image.AddIcon />
              {children}
            </AntdButton>
          </Upload>
        </div>
      );
    }

    return null;
  }
}

export default UploadFormItem;

interface File {
  id: string;
  url: string;
  file_hash: string;
  old_url: string;
  is_processed: boolean;
  path: string;
  content_type: string;
  attributes: { width: number; height: number };
  size: number;
  name: string;
  created_at: string;
}
