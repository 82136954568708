import React from "react";

// tslint:disable: max-line-length
const Select: React.FC = () => (
  <svg width="0.125in" height="0.167in">
    <path
      fill="rgb(31, 50, 95)"
      d="M4.594,1.906 L1.537,4.994 L0.594,4.041 L4.594,0.000 L8.594,4.041 L7.650,4.994 L4.594,1.906 ZM4.594,10.094 L7.650,7.006 L8.594,7.959 L4.594,11.999 L0.594,7.959 L1.537,7.006 L4.594,10.094 Z"
    />
  </svg>
);

const Forward: React.FC = () => (
  <svg width="16px" height="12px">
    <path
      fill="rgb(66, 82, 110)"
      d="M10.308,0.500 L9.261,1.604 L13.166,5.720 L-0.000,5.720 L-0.000,7.280 L13.166,7.280 L9.261,11.396 L10.308,12.500 L16.000,6.500 L10.308,0.500 Z"
    />
  </svg>
);

const Back: React.FC = () => (
  <svg width="16px" height="16px">
    <path
      fill="rgb(31, 50, 95)"
      d="M7.673,15.677 L0.000,7.999 L7.673,0.321 C8.102,-0.108 8.798,-0.108 9.227,0.321 L9.227,0.321 C9.656,0.751 9.656,1.447 9.227,1.876 L4.207,6.899 L14.901,6.899 C15.508,6.899 16.000,7.392 16.000,7.999 L16.000,7.999 C16.000,8.606 15.508,9.099 14.901,9.099 L4.207,9.099 L9.227,14.122 C9.656,14.551 9.656,15.247 9.227,15.677 L9.227,15.677 C8.798,16.106 8.102,16.106 7.673,15.677 Z"
    />
  </svg>
);

export default {
  Select,
  Forward,
  Back
};
