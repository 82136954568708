/**
 *
 * `classnames` is a utility for conditionally joining classNames together
 *
 * The classNames function takes any number of arguments which can be a string or object.
 * The argument 'foo' is short for { foo: true }.
 *
 *  If the value associated with a given key is falsy, that key won't be included in the output.
 *
 * @example
 * classNames('foo', 'bar'); // => 'foo bar'
 * classNames('foo', { bar: true }); // => 'foo bar'
 * classNames({ 'foo-bar': true }); // => 'foo-bar'
 * classNames({ 'foo-bar': false }); // => ''
 * classNames({ foo: true }, { bar: true }); // => 'foo bar'
 * classNames({ foo: true, bar: true }); // => 'foo bar'
 *
 */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const classNames = (...args: any[]): string | undefined => {
  const hasOwn = {}.hasOwnProperty;
  const classes = [];

  for (const arg of args) {
    if (!arg) {
      continue;
    }

    const argType = typeof arg;

    if (argType === "string" || argType === "number") {
      classes.push(arg);
    } else if (Array.isArray(arg) && arg.length) {
      const inner = classNames(...arg);

      if (inner) {
        classes.push(inner);
      }
    } else if (argType === "object") {
      for (const key in arg) {
        if (hasOwn.call(arg, key) && arg[key]) {
          classes.push(key);
        }
      }
    }
  }

  return classes.join(" ");
};

export default classNames;
