/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { AppSession } from "@ebs-platform/common";
import SessionGuard from "./SessionGuard";
import "@ebs-platform/common/dist/styles.css";
import "@ebs-platform/components/lib/index.scss";
import {
  Bootstrap,
  BootstrapConfig,
  ServiceTypes
} from "@ebs-platform/components";
import PublicRoutes from "libs/router/PublicRoutes";
import { AccountProvider } from "contexts";
import "./App.css";

const config: BootstrapConfig = {
  [ServiceTypes.SSO]: { baseUrl: process.env.REACT_APP_BASE_SSO! },
  [ServiceTypes.ORGANIZATION]: { baseUrl: process.env.REACT_APP_BASE_API! }
};

/**
 * This is the "entry" component of this application.
 * Its job is to render routes wrapped by session context.
 *
 * This is the comonent mounted in the #root element.
 */
const App: React.FC = () => (
  <Bootstrap config={config}>
    <BrowserRouter>
      <AppSession>
        <AccountProvider>
          <Route>
            <PublicRoutes />
            <SessionGuard />
          </Route>
        </AccountProvider>
      </AppSession>
    </BrowserRouter>
  </Bootstrap>
);

export default App;
