import React from "react";
import "./Header.css";

export interface HeaderProps {
  title: string;
  action?: React.ReactChild;
}

const Header: React.FC<HeaderProps> = ({ title, action }) => (
  <>
    <div className="header">
      <span className="header__title">{title}</span>
      {action ? action : null}
    </div>
  </>
);

export default Header;
