import React from "react";
import { Link } from "libs/router";
import "./SignupAlert.css";

const SignupAlert: React.FC = () => {
  return (
    <div className="signup-alert">
      {"Don't have an account?"}
      <Link route="Authentication" params={{ method: "registration" }}>
        <span className="signup-alert__link">sign up</span>
      </Link>
    </div>
  );
};

export default SignupAlert;
