import React from "react";
import { classnames } from "libs/utils";
import "./Container.css";

export interface ContainerTypes {
  children: React.ReactChild;
  className?: string;
  dashed?: boolean;
  footer?: React.ReactChild;
}

const Container: React.FC<ContainerTypes> = ({
  className,
  dashed,
  children,
  footer
}) => {
  return (
    <div
      className={classnames("container", className, {
        "container--dashed": dashed
      })}
    >
      {children}
      {footer && <div className="container__footer">{footer}</div>}
    </div>
  );
};

export default Container;
