import React from "react";
import "./Separator.css";

export interface SeparatorTypes {
  width?: number | string;
  margin?: number | string;
  top?: number | string;
  bottom?: number | string;
}

const Separator: React.FC<SeparatorTypes> = ({
  width,
  margin,
  top,
  bottom
}) => (
  <div
    className="separator"
    style={{ width, marginTop: top || margin, marginBottom: bottom || margin }}
  />
);

Separator.defaultProps = {
  width: "100%"
};

export default Separator;
