import React from "react";
import { logo } from "components/svg";

export interface LogoTypes {
  size?: number;
}

const Logo: React.FC<LogoTypes> = ({ size = 45 }) => (
  <img height={size} src={logo} alt="logo" />
);

export default Logo;
